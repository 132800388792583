<template>
  <div>
    <div v-for="(address,index) in addresses"
      :key="index">
      <div v-if="!address.isDeleted">
        <div v-if="index > 0"
          class="is-divider" />
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select v-model="address.type">
                <option v-for="(a,value) in addressTypes"
                  :value="value"
                  :key="a">{{ a }}</option>
              </select>
            </span>
          </p>
          <p class="control is-expanded">
            <mapbox-auto-complete :id="'address'+index"
              :address="address"
              :v="v.$each[index]" />
          </p>
          <p class="control">
            <a class="button is-gold is-outlined"
              @click="setDefault(index)">
              <span class="icon is-medium">
                <i class="default mdi mdi-24px"
                  :class="[address.isDefault ? 'mdi-star': 'mdi-star-outline']" />
              </span>
            </a>
          </p>
          <p class="control">
            <a class="button is-danger is-outlined"
              @click="deleteAddress(index, address)">
              <span class="icon is-medium">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="control is-pulled-right">
      <a class="button is-primary tooltip is-tooltip-topright"
        @click="addAddress()"
        :disabled="v.$invalid"
        data-tooltip="Add new address">
        <span class="icon is-small">
          <i class="mdi mdi-plus mdi-24px" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import MapboxAutoComplete from './MapboxAutoComplete'
import AddressTypes from '@/enums/AddressTypes'

export default {
  name: 'MapboxAddressList',
  components: {
    MapboxAutoComplete
  },
  props: {
    addresses: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  computed: {
    addressTypes: function() {
      return AddressTypes
    },
    activeAddresses: function() {
      if (this.addresses) {
        return this.addresses.filter(x => !x.isDeleted)
      }
      return []
    }
  },
  methods: {
    setDefault: function(index) {
      this.v.$touch()
      this.addresses.map(function(e, i) {
        e.isDefault = i === index
      })
    },

    addAddress: function() {
      if (this.v.$invalid) {
        return
      }
      this.v.$touch()
      const newAddress = {
        id: null,
        type: 0,
        addressType: 1,
        address1: '',
        address2: '',
        address3: '',
        suburbText: '',
        suburbPostcode: '',
        stateText: '',
        stateCode: '',
        countryCode: '',
        countryText: '',
        assetID: this.assetId,
        isDefault: this.activeAddresses.filter(x => x.isDefault).length === 0,
        isDeleted: false,
        isNew: true
      }
      this.addresses.push(newAddress)
    },
    deleteAddress: function(index, address) {
      this.v.$touch()

      if (address.isNew) {
        this.addresses.splice(index, 1)
      } else {
        // address.isNew = false
        address.isDeleted = true
        address.address1 = 'deleted'
        address.suburbText = 'deleted'
        address.suburbPostcode = 'deleted'
        address.stateCode = 'deleted'
        address.stateText = 'deleted'
        address.countryCode = 'deleted'
        address.countryText = 'deleted'
      }

      if (address.isDefault && this.activeAddresses.length > 0) {
        this.activeAddresses[0].isDefault = true
      }
    }
  }
}
</script>
