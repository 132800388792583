<template>
  <div>
    <div class="field has-addons">
      <div class="control is-expanded">
        <bulma-autocomplete :id="id+ '_address'"
          placeholder="Start typing address"
          v-model="address.address1"
          :data="suggestions.data"
          field="text"
          @input="mapboxSearch"
          :loading="suggestions.isFetching"
          @select="onStreetAddressSelect($event)"
          :class-prop="!v.address1.$error ? '' : 'is-danger'"
          :focus-inserted="address.isNew"
          :max-results="mapboxMap.limit">
          <template slot-scope="props">
            <div>
              <span class="icon is-small">
                <i class="mdi mdi-map-marker" />
              </span>
              {{ `${props.option.place_name}` }}
            </div>
          </template>
        </bulma-autocomplete>
        <span class="help is-danger"
          v-if="v.address1 && !v.address1.required">Address is required
        </span>
      </div>
    </div>
    <div class="field">
      <div class="control is-expanded">
        <input class="input is-capitalized fix-rounded"
          type="text"
          v-model="address.address2"
          placeholder="Address Line 2">
      </div>
    </div>
    <div class="field">
      <div class="columns">
        <div class="column">
          <label class="label">Suburb</label>
          <div class="control">
            <bulma-autocomplete :id="id+ '_address1'"
              v-model="address.suburbText"
              placeholder="Start typing to search"
              :data="localities.data"
              field="text"
              @input="mapboxLocalitySearch"
              :loading="localities.isFetching"
              @select="onSuburbSelect($event)"
              :class-prop="!v.suburbText.$error? 'fix-rounded' : 'fix-rounded is-danger'"
              :max-results="mapboxMap.limit">
              <template slot-scope="props">
                <div>
                  <span class="icon is-small">
                    <i class="mdi mdi-map-marker" />
                  </span>
                  {{ `${props.option.place_name}` }}
                </div>
              </template>
            </bulma-autocomplete>
            <span class="help is-danger"
              v-if="v.suburbText && !v.suburbText.required">Suburb is required
            </span>
          </div>
        </div>
        <div class="column is-4">
          <label class="label">State</label>
          <div class="control">
            <input class="input is-capitalized fix-rounded"
              type="text"
              v-model="address.stateText"
              placeholder="State"
              v-fq-validate="v.stateText"
              @input="onStateChanged">
            <span class="help is-danger"
              v-if="v.stateCode && !v.stateCode.required">State is required
            </span>
          </div>
        </div>
        <div class="column is-2">
          <label class="label">Postcode</label>
          <div class="control">
            <input class="input fix-rounded"
              type="text"
              v-model="address.suburbPostcode"
              placeholder="Postcode"
              v-fq-validate="v.suburbPostcode">
            <span class="help is-danger"
              v-if="v.suburbPostcode && !v.suburbPostcode.required">Postcode is required
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Country</label>
      <div class="field-body">
        <div class="control">
          <input class="input is-capitalized fix-rounded"
            type="text"
            v-model="address.countryText"
            placeholder="Country Code"
            v-fq-validate="v.countryText"
            @input="onCountryChanged">
          <span class="help is-danger"
            v-if="v.countryCode && !v.countryCode.required">Country is required
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InputValidationDirective } from '@/components/directives'
import MapboxApi from '@/services/mapbox-api'
import _debounce from 'lodash.debounce'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import Countries from '@/assets/json/countries.json'
import Neighbours from '@/assets/json/neighbours.json'

export default {
  name: 'MapboxAutoComplete',
  components: {
    BulmaAutocomplete
  },
  mixins: [InputValidationDirective],
  props: {
    id: {
      type: String,
      default: ''
    },
    address: null,
    v: null
  },
  data() {
    return {
      mapboxMap: {
        places: '/mapbox.places',
        autoComplete: '?autocomplete=true',
        accessToken: 'pk.eyJ1IjoiY2luaXgxIiwiYSI6ImNqYzV1YWR5bTFhd3czM255b3FwdWl5aG0ifQ.G2Llp-QEtZ7V9UKOXv-ASA',
        limit: 6,
        proximity: ''
      },
      suggestions: {
        data: [],
        query: '',
        selected: null,
        isFetching: false
      },
      localities: {
        data: [],
        query: '',
        selected: null,
        isFetching: false
      },
      country: null,
      geoCodingTypes: {
        address: 'address',
        locality: 'locality',
        postcode: 'postcode',
        region: 'region',
        country: 'country'
      },
      countries: []
    }
  },
  computed: {
    countryCodes() {
      let neighbours = Neighbours.find((n) => n.countryCode === this.$company.info.countryCode) || []
      return [this.$company.info.countryCode, ...neighbours.neighbours]
    }
  },
  created() {
    this.getCountries()
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    mapboxSearch: _debounce(async function () {
      if (this.address.address1) {
        this.v.address1.$touch()

        this.suggestions.data = []
        this.suggestions.isFetching = true
        const country = this.countries.join(',')
        const url = `${this.mapboxMap.places}/${this.address.address1}.json?access_token=${this.mapboxMap.accessToken}&country=${country}&proximity=${this.mapboxMap.proximity}&types=${this.geoCodingTypes.address}&${this.mapboxMap.autoComplete}&limit=${this.mapboxMap.limit}`
        const result = await MapboxApi.get(url)
        const vm = this
        result.data.features.map(function (f) {
          // Customise address value for binding
          f.text = f.address ? `${f.address} ${f.text}` : f.text
          vm.suggestions.data.push(f)
        })
        vm.suggestions.isFetching = false
      }
    }, 500),
    mapboxLocalitySearch: _debounce(async function () {
      if (this.address.suburbText) {
        this.v.suburbText.$touch()

        this.localities.data = []
        this.localities.isFetching = true
        const url = `${this.mapboxMap.places}/${this.address.suburbText}.json?access_token=${this.mapboxMap.accessToken}&country=${this.countries.join(
          ','
        )}&proximity=${this.mapboxMap.proximity}&types=${this.geoCodingTypes.locality}&${this.mapboxMap.autoComplete}&limit=${this.mapboxMap.limit}`
        const result = await MapboxApi.get(url)
        const vm = this
        result.data.features.map(function (f) {
          // Customise dropdown display suggestions
          const state = f.context.find((context) => context.id.startsWith(vm.geoCodingTypes.region)).text
          const postcode = f.context.find((context) => context.id.startsWith(vm.geoCodingTypes.postcode)).text
          const country = f.context.find((context) => context.id.startsWith(vm.geoCodingTypes.country)).text
          f.place_name = `${f.text}, ${state} ${postcode}, ${country}`
          vm.localities.data.push(f)
        })
        this.localities.isFetching = false
      }
    }, 500),
    onStreetAddressSelect(event) {
      if (event) {
        this.v.$touch()
        this.suggestions.selected = event
        const contexts = this.suggestions.selected.context
        const countryData = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.country))
        const stateData = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.region))
        this.address.suburbText = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.locality)).text
        this.address.suburbPostcode = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.postcode)).text
        this.address.countryText = countryData.text
        this.address.countryCode = countryData.short_code.toUpperCase()
        this.address.stateText = stateData.text
        this.address.stateCode = stateData.short_code.split('-')[1]
      }
    },
    onSuburbSelect(event) {
      if (event) {
        this.v.$touch()
        this.localities.selected = event
        const contexts = this.localities.selected.context
        const countryData = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.country))
        const stateData = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.region))
        this.address.suburbPostcode = contexts.find((context) => context.id.startsWith(this.geoCodingTypes.postcode)).text
        this.address.stateCode = stateData.short_code.split('-')[1]
        this.address.stateText = stateData.text
        this.address.countryText = countryData.text
        this.address.countryCode = countryData.short_code.toUpperCase()
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        const vm = this
        navigator.geolocation.getCurrentPosition(function (position) {
          vm.mapboxMap.proximity = `${position.coords.longitude},${position.coords.latitude}`
        })
      }
    },
    onStateChanged(event) {
      this.address.stateCode = event.target.value
    },
    onCountryChanged(event) {
      const country = Countries.find((c) => c.name.toLowerCase() === this.address.countryText.toLowerCase())
      this.address.countryCode = country ? country['alpha-2'] : ''
    },
    getCountries() {
      let neighbours = Neighbours.find((n) => n.countryCode === this.$company.info.countryCode) || []
      this.countries = [this.$company.info.countryCode, ...neighbours.neighbours]
    }
  }
}
</script>

<style lang="scss" scoped>
input.fix-rounded {
  border-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
</style>
