<!-- Based on https://github.com/vue-bulma/pagination -->
<template>
  <nav :class="getNavClassName()">
    <a 
      class="pagination-previous"
      :disabled="outOfRegion(formatCurrentPage - 1)"
      @click="onPageClick(currentPage - 1)">Previous</a>
    <a 
      class="pagination-next"
      :disabled="outOfRegion(formatCurrentPage + 1)"
      @click="onPageClick(currentPage + 1)">Next page</a>
    <ul class="pagination-list">
      <li 
        v-for="(item, index) in pagingList"
        :key="index">
        <a 
          v-if="item !== '...'"
          :class="getPagingClassName(item)"
          @click="onPageClick(item)">{{ item }}</a>
        <span 
          v-else
          class="pagination-ellipsis">...</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import Paging from './BulmaPaging.js'

export default {
  name: 'BulmaPagination',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    displayPage: {
      // Number of page buttons to display
      type: Number,
      default: 4
    },
    totalRows: {
      type: Number
    },
    pageSize: {
      type: Number,
      default: 10
    },
    modifiers: {
      type: String,
      default: ''
    },
    prev: {
      type: String,
      default: 'Previous'
    },
    next: {
      type: String,
      default: 'Next Page'
    }
  },
  data() {
    return {
      // lastPage: 0
    }
  },
  computed: {
    lastPage() {
      return Math.ceil(this.totalRows / this.pageSize)
    },
    pagingList() {
      return Paging(this.currentPage, this.lastPage, this.displayPage)
    },
    formatCurrentPage() {
      const currentPage = Number(this.currentPage)
      return currentPage > 0 ? currentPage : 1
    },
    prevPage() {
      return Math.max(this.formatCurrentPage - 1, 1)
    },
    nextPage() {
      return Math.min(this.formatCurrentPage + 1, this.lastPage)
    }
  },
  mounted() {},
  methods: {
    getNavClassName() {
      var optional = ['', 'is-centered', 'is-right']
      if (['', 'is-centered', 'is-right'].indexOf(this.modifiers.trim()) >= 0) {
        return 'pagination ' + this.modifiers
      } else {
        console.warn(
          ' modifiers %s is not within the options ',
          this.modifiers,
          optional,
          '\n see more detail https://github.com/vue-bulma/vue-bulma-pagination#doc'
        )
        return 'pagination'
      }
    },
    getPagingClassName(item) {
      return this.currentPage !== item ? 'pagination-link' : 'pagination-link is-current'
    },
    outOfRegion(page) {
      return page < 1 || page > this.lastPage
    },
    onPageClick(item) {
      if (this.outOfRegion(item)) {
        return
      }
      this.$emit('pageChanged', item)
    }
  }
}
</script>
