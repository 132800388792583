<template>
  <div :class="{'tile is-parent is-vertical' : true}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p v-if="isTiled"
        class="title">Emails</p>
      <div v-show="!email.isDeleted"
        class="field"
        v-for="(email,index) in emails"
        :key="email.id">
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select v-model="email.type">
                <option v-for="(value, p) in emailTypes"
                  :value="value"
                  :key="p">{{ p }}</option>
              </select>
            </span>
          </p>
          <p class="control is-expanded has-icons-left">
            <input class="input"
              type="email"
              placeholder="Email"
              v-model="email.address"
              v-fq-validate="v.$each[index].address"
              v-focus-inserted="{ focus: email.isNew}">
            <span class="icon is-small is-left">
              <i class="mdi mdi-email mdi-18px" />
            </span>
          </p>
          <p class="control">
            <a class="button is-gold is-outlined"
              @click="setDefault(email, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-24px"
                  :class="[email.isDefault ? 'mdi-star': 'mdi-star-outline']" />
              </span>
            </a>
          </p>
          <p class="control">
            <a class="button is-danger is-outlined"
              @click="deleteEmail(email, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </a>
          </p>
        </div>
        <div class="field">
          <span class="help is-danger"
            v-if="!v.$each[index].address.required">Email Address field is required</span>
          <span class="help is-danger"
            v-if="!v.$each[index].address.email && v.$each[index].address.required">Email Address field is invalid</span>
        </div>
      </div>
      <div class="field">
        <p class="control is-pulled-right">
          <a class="button is-primary tooltip is-tooltip-topright"
            :disabled="v.$invalid"
            @click="addEmail()"
            data-tooltip="Add new email">
            <span class="icon is-small">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </p>
      </div>
    </article>
  </div>
</template>

<script>
import EmailTypes from '@/enums/EmailTypes'
import { InputValidationDirective } from '@/components/directives'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'EmailList',
  directives: {
    FocusInserted
  },
  mixins: [InputValidationDirective],
  props: {
    emails: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  computed: {
    emailTypes: function () {
      return EmailTypes
    },
    activeEmails: function () {
      if (typeof this.emails !== 'undefined') {
        return this.emails.filter((x) => !x.isDeleted)
      }
      return []
    }
  },
  methods: {
    setDefault: function (email, index) {
      this.emails.map(function (e, i) {
        e.isDefault = i === index
      })
    },
    addEmail: function () {
      if (this.v.$invalid) {
        return
      }

      const newEmail = {
        id: null,
        type: 0,
        isDefault: this.activeEmails.filter((x) => x.isDefault).length === 0,
        isNew: true,
        isDeleted: false,
        address: ''
      }

      this.emails.push(newEmail)
    },
    deleteEmail: function (email, index) {
      // email.isNew = false // otherwise it's sent to server and inserted
      if (email.isNew) {
        this.emails.splice(index, 1)
      } else {
        email.isDeleted = true
        email.address = 'deleted@deleted.com' // to avoid validation errors
      }
      if (email.isDefault && this.activeEmails.length > 0) {
        this.activeEmails[0].isDefault = true
      }
    }
  }
}
</script>
