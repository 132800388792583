<template>
  <div>
    <div class="carousel carousel-animated carousel-animate-slide"
      :class="{ 'is-loading-image-popup' : isLoading}">
      <section v-if="isLoading && !imageBase64"
        class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large"
            style="opacity: 0;">
            <i class="mdi mdi-spin mdi-loading mdi-36px" />
          </span>
        </div>
      </section>
      <div class="carousel-container">
        <div class="carousel-item has-background is-active">
          <img class="is-background"
            src=""
            alt=""
            width="800"
            height="600">
        </div>
        <div class="carousel-item has-background"
          style="overflow:visible">
          <div id="img-zoomer-box">
            <img class="is-background"
              id="img-1"
              :src="imageBase64"
              alt=""
              width="800"
              height="600"
              @mousemove="handleMouseOver"
              @mouseleave="showZoom = false"
              @click="handleImageClick">
            <div v-if="showZoom"
              id="img-2">
              <div id="img-2-bg" />
            </div>
          </div>
          <div v-if="imageBase64"
            class="title">{{ imageIndex + 1 }} of {{ imageCount }}</div>
        </div>
      </div>
      <div class="carousel-navigation is-overlay">
        <div class="carousel-nav-left">
          <i class="mdi mdi-36px mdi-chevron-left"
            :class="{'mdi-dark mdi-inactive' : imageIndex === 0}"
            aria-hidden="true"
            @click="previousImage()" />
        </div>
        <div class="carousel-nav-right">
          <i class="mdi mdi-36px mdi-chevron-right"
            :class="{'mdi-dark mdi-inactive' : imageIndex === imageCount - 1}"
            aria-hidden="true"
            @click="nextImage()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageService from './ImageService'
import { ImageOrientationTypes } from '@/enums'

export default {
  name: 'BulmaCarousel',
  props: {
    imageIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 0
    },
    imageWidth: {
      type: Number,
      default: 800
    },
    imageHeight: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      imageBase64: '',
      imageIndex: this.startIndex,
      imageCount: this.imageIds.length,
      isLoading: false,
      images: [],
      showZoom: false,
      showMagnifier: false,
      zoom: {
        scale: 1.5,
        zoom: true
      }
    }
  },
  computed: {
    isLoadingFirstImage() {
      return !this.images[this.startIndex] || this.images[this.startIndex] === undefined
    }
  },
  watch: {
    imageBase64(newVal, oldVal) {
      this.notifyOrientation()
    },
    // change cursor zoom in / zoom out when zoom changes
    zoom: {
      handler(newVal, oldVal) {
        const imgStyle = document.querySelector('#img-2-bg').style
        const style = document.querySelector('#img-zoomer-box').style

        if (newVal.zoom) {
          style.cursor = 'zoom-in'
        } else {
          style.cursor = 'zoom-out'
        }

        let original = document.querySelector('#img-1')

        let imgWidth = original.offsetWidth
        let imgHeight = original.offsetHeight
        imgStyle.backgroundSize = imgWidth * 1.5 * this.zoom.scale + 'px ' + imgHeight * 1.5 * this.zoom.scale + 'px'
      },
      deep: true
    }
  },
  created() {
    this.images = new Array(this.imageIds.length)
    this.initSelectedIds(this.imageIds.length)
    this.getImage()
    // this.getThumbnails()
  },
  mounted() {
    window.addEventListener('wheel', this.onWheel)
  },
  methods: {
    async getThumbnail(id, index) {
      var thumbnail = await ImageService.getImageThumbnail(id, 800)
      this.images.splice(index, 1, thumbnail)
    },
    async getThumbnails() {
      this.isLoading = true
      const vm = this
      this.images = new Array(this.imageIds.length)
      this.initSelectedIds(this.imageIds.length)
      const promises = this.imageIds.map(function (id, index) {
        vm.getThumbnail(id, index)
      })
      await Promise.all(promises)
      // console.log(this.images[this.startIndex])
      this.isLoading = false
    },
    initSelectedIds(length) {
      this.imagesSelected = new Array(length)
      let size = length
      while (size--) {
        this.imagesSelected.splice(size, 1, false)
      }
    },
    async getImage(action) {
      this.isLoading = true
      // if (action === 'prev') {
      //   this.images[0] = !this.images[1] ? this.images[1] : (this.imageIndex > 0 ? await ImageService.getImageThumbnail(this.imageIds[this.imageIndex - 1], 800) : '')
      //   this.images[1] = !this.images[2] ? this.images[2] : await ImageService.getImageThumbnail(this.imageIds[this.imageIndex], 800)
      //   this.images[2] = await ImageService.getImageThumbnail(this.imageIds[this.imageIndex + 1], 800)
      // } else if (action === 'next') {
      //   this.images[0] = await ImageService.getImageThumbnail(this.imageIds[this.imageIndex - 1], 800)
      //   this.images[1] = !this.images[0] ? this.images[0] : await ImageService.getImageThumbnail(this.imageIds[this.imageIndex], 800)
      //   this.images[2] = this.imageIndex < this.imageCount - 1 ? await ImageService.getImageThumbnail(this.imageIds[this.imageIndex + 1], 800) : ''
      // } else {
      //   this.images[0] = this.imageIndex > 0 ? await ImageService.getImageThumbnail(this.imageIds[this.imageIndex - 1], 800) : ''
      //   this.images[1] = await ImageService.getImageThumbnail(this.imageIds[this.imageIndex], 800)
      //   this.images[2] = this.imageIndex < this.imageCount - 1 ? await ImageService.getImageThumbnail(this.imageIds[this.imageIndex + 1], 800) : ''
      // }
      if (this.images[this.imageIndex] !== undefined) {
        this.imageBase64 = this.images[this.imageIndex]
      } else {
        this.imageBase64 = await ImageService.getImageThumbnail(this.imageIds[this.imageIndex], 800)
        this.images.splice(this.imageIndex, 1, this.imageBase64)
      }
      this.isLoading = false
    },
    previousImage() {
      if (this.imageIndex > 0) {
        this.imageIndex--
        this.getImage('prev')
      }
    },
    nextImage() {
      if (this.imageIndex < this.imageCount - 1) {
        this.imageIndex++
        this.getImage('next')
      }
    },
    notifyOrientation() {
      const i = new Image()
      const vm = this
      i.onload = function () {
        // console.log({ w: i.width, h: i.height })
        const o = i.width > i.height ? ImageOrientationTypes.Landscape : ImageOrientationTypes.Portrait
        vm.$emit('orientation', o)
      }
      i.src = this.images[this.imageIndex]
    },
    handleMouseOver(event) {
      if (this.showMagnifier) {
        let x = event.offsetX || layerX
        let y = event.offsetY || layerY
        this.handleImageAndMousePosition(event, x, y)
      }
    },
    handleImageAndMousePosition(event, offsetX, offsetY) {
      this.showZoom = true
      // original image
      let original = document.querySelector('#img-1')
      // magnified image magnifier/container
      let magnified = document.querySelector('#img-2')
      // image inside of magnifier
      let magnifiedImage = document.querySelector('#img-2-bg')

      let style = magnified?.style

      let imgStyle = magnifiedImage?.style

      if (!style || !imgStyle) return

      // setting background image on the fly
      imgStyle.backgroundImage = 'url(' + original.src + ')'

      // offsets when mouse moves over image and layers when clicked on image

      let x = offsetX
      let y = offsetY

      let imgWidth = original.offsetWidth
      let imgHeight = original.offsetHeight

      let xperc = (x / imgWidth) * 100
      let yperc = (y / imgHeight) * 100

      //lets user scroll past right edge of image
      if (x > 0.01 * imgWidth) xperc += 0.15 * xperc
      //lets user scroll past bottom edge of image
      if (y >= 0.01 * imgHeight) yperc += 0.15 * yperc

      imgStyle.backgroundSize = imgWidth * 1.5 * this.zoom.scale + 'px ' + imgHeight * 1.5 * this.zoom.scale + 'px'

      imgStyle.backgroundPositionX = xperc + '%'
      imgStyle.backgroundPositionY = yperc + '%'
      // position of the magnifying glass
      let posY = 0
      let posX = 0
      //// when the magnified image tries to go beyond the viewport (x axis)
      if (event.pageY + 300 > event.view.innerHeight) {
        posY = event.view.innerHeight - 600
      } else if (event.pageY - 300 < 0) posY = 0
      else posY = event.pageY - 300

      //// when the magnified image tries to go beyond the viewport (y axis)
      if (event.pageX + 300 > event.view.innerWidth) {
        posX = event.view.innerWidth - 600
      } else if (event.pageX - 300 < 0) posX = 0
      else posX = event.pageX - 300

      style.left = posX + 'px'
      style.top = posY + 'px'
    },
    async handleImageClick(event) {
      if (!this.showMagnifier) {
        this.showMagnifier = true
        this.showZoom = true
        // await 1 second before showing magnifier, will not work without this as the image is not mounted otherwise
        await new Promise((resolve) => setTimeout(resolve, 10))
        this.handleImageAndMousePosition(event, event.layerX, event.layerY)
      } else {
        this.showMagnifier = false
        this.showZoom = false
      }
    },
    onWheel(event) {
      // delta Y is scroll up and down
      // showZoom is true when mouse is over image
      if (event.deltaY > 0 && this.showZoom) {
        if (this.zoom.scale > 1.5) this.zoom.scale = this.zoom.scale - 0.5
      } else if (event.deltaY < 0 && this.showZoom) {
        if (this.zoom.scale < 5) this.zoom.scale = this.zoom.scale + 0.5
      }

      if (this.zoom.scale >= 5) this.zoom.zoom = false
      if (this.zoom.scale <= 1.5) this.zoom.zoom = true
    }
  }
}
</script>

<style>
#img-zoomer-box {
  height: auto;
  position: relative;
  margin: 10px auto;
}

#img-1 {
  width: 100%;
  height: auto;
}

#img-zoomer-box:hover,
#img-zoomer-box:active {
  display: block;
  cursor: zoom-in;
}

#img-zoomer-box:hover #img-2,
#img-zoomer-box:active #img-2 {
  opacity: 1;
}
#img-2 {
  width: 600px;
  height: 600px;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  position: fixed;
  border: 4px solid whitesmoke;
  z-index: 999;
  border-radius: 100%;
  opacity: 0;
  display: block;
  overflow: hidden !important;
  transition: opacity 0.2s;
}

#img-2-bg {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 100%;
  /* transition: background-position 0.2s; */
}
</style>
