import FqApi from '@/services/fq-api'

export default {
  async getImageThumbnail(id, size) {
    const url = `/quotes/images/thumbnail/${id}?size=${size}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  }
}
