<template>
  <base-modal-ex
    id="pdf-modal"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="true"
    :min-width="minWidth"
    @update:active="close()">
    <div class="box">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <h4
              v-if="title"
              class="title is-5">{{ title }}</h4>
            <bulma-carousel
              :image="image"
              :image-width="imageWidth"
              :image-height="imageHeight"
              :image-ids="imageIds"
              :start-index="startIndex"
              @orientation="setOrientation" />
          </div>
        </div>
      </article>
    </div>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import BulmaCarousel from './BulmaCarousel'
import { ImageOrientationTypes } from '@/enums'

export default {
  name: 'BulmaCarouselModal',
  components: {
    BaseModalEx,
    BulmaCarousel
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    imageIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 0
    },
    imageWidth: {
      type: Number,
      default: 800
    },
    imageHeight: {
      type: Number,
      default: 600
    },
    image: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.active || false,
      minWidth: 30
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit('close')
    },
    previousImage() {
      this.$emit('previousImage')
    },
    nextImage() {
      this.$emit('nextImage')
    },
    setOrientation(value) {
      this.minWidth = value === ImageOrientationTypes.Portrait ? 30 : 60
    }
  }
}
</script>
