<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <div class="columns is-gapless">
        <div class="column">
          <p
            v-if="isTiled"
            class="title">Addresses</p>
        </div>
        <div class="column is-narrow">
          <div
            class="dropdown is-right"
            :class="{ 'is-active': dropdownActive }"
            @mouseleave="dropdownActive = false">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu dropdown-item-has-icon"
                @click="toogleDropdown()">
                <span class="icon is-small is-left">
                  <i
                    class="mdi mdi-18px"
                    :class="[selectedMapSource == 'Google' ? 'mdi-google-maps' : 'mdi-map-marker-radius']" />
                </span>
                <span>{{ selectedMapSource }}</span>
                <span class="icon is-small">
                  <i
                    class="mdi mdi-chevron-down"
                    aria-hidden="true" />
                </span>
              </button>
            </div>
            <div
              class="dropdown-menu"
              role="menu">
              <div class="dropdown-content">
                <a
                  href="#"
                  class="dropdown-item dropdown-item-has-icon"
                  id="AddressListGoogle"
                  @click="selectMapSource($event)"
                  :class="{'is-active' : mapSource === 'AddressListGoogle'}">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-google-maps mdi-18px" />
                  </span>
                  <span class="dropdown-item-text">Google</span>
                </a>
                <a
                  href="#"
                  class="dropdown-item dropdown-item-has-icon"
                  id="AddressListMapbox"
                  @click="selectMapSource($event)"
                  :class="{'is-active' : mapSource === 'AddressListMapbox'}">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-map-marker-radius mdi-18px" />
                  </span>
                  <span class="dropdown-item-text">Mapbox</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <component
        :is="mapSource"
        :addresses="addresses"
        :is-tiled="isTiled"
        :v="v" />
    </article>
  </div>
</template>

<script>
import AddressListGoogle from './AddressListGoogle'
import AddressListMapbox from './AddressListMapbox'

export default {
  name: 'AddressView',
  components: {
    AddressListGoogle,
    AddressListMapbox
  },
  props: {
    addresses: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  data() {
    return {
      mapSource: 'AddressListGoogle',
      dropdownActive: false
    }
  },
  computed: {
    selectedMapSource() {
      return this.mapSource === 'AddressListMapbox' ? 'Mapbox' : 'Google'
    }
  },
  methods: {
    selectMapSource(event) {
      this.dropdownActive = false
      this.mapSource = event.target.id
    },
    toogleDropdown() {
      this.dropdownActive = !this.dropdownActive
    }
  }
}
</script>

<style lang="scss" scoped>
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
</style>