<template>
  <bulma-autocomplete
    placeholder="Suburb"
    v-model="suburb"
    :data="suburbs.data"
    field="suburb"
    :loading="suburbs.loading"
    @input="searchSuburb($event)"
    @select="pickSuburb($event)">
    <template slot-scope="props">
      <div>
        <span class="icon is-small">
          <i class="mdi mdi-map-marker" />
        </span>
        {{ `${props.option.suburb}, ${props.option.state}, ${props.option.country}` }}
      </div>
    </template>
  </bulma-autocomplete>
</template>

<script>
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import _debounce from 'lodash/debounce'
import Neighbours from '@/assets/json/neighbours.json'

export default {
  name: 'GoogleSuburbAutocomplete',
  components: {
    BulmaAutocomplete
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    v: null,
    bounds: null
  },
  data() {
    return {
      googleApi: {
        mapUrl: 'https://maps.googleapis.com/maps/api/js',
        mapKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        mapLibraries: 'places',
        mapCallback: 'initMap',
        mapStatus: null,
        bounds: null
      },
      suburbs: {
        data: [],
        loading: false
      },
      suburb: ''
    }
  },
  computed: {
    countryCodes() {
      let neighbours = Neighbours.find(n => n.countryCode === this.$company.info.countryCode) || []
      return [this.$company.info.countryCode, ...neighbours.neighbours]
    }
  },
  watch: {
    suburb: function(newVal, oldVal) {
      this.$emit('input', newVal)
    }
  },
  created() {
    this.suburb = this.value
    const vm = this
    window.initMap = function() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          const circle = new window.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          })

          vm.googleApi.bounds = circle.getBounds()
        })
      }

      vm.googleApi.mapStatus = 'AddressDetail'
    }

    if (!window.google) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `${this.googleApi.mapUrl}?key=${this.googleApi.mapKey}&libraries=${this.googleApi.mapLibraries}&callback=${this.googleApi.mapCallback}`
      document.body.appendChild(script)
    } else {
      window.initMap()
    }
  },
  mounted() {},
  methods: {
    searchSuburb: _debounce(function(key) {
      // this.v.suburbText.$touch()
      this.suburbs.loading = true
      if (key.length > 0) {
        const searchOptions = {
          input: key,
          types: ['(cities)'],
          componentRestrictions: {
            country: this.countryCodes
          }
        }

        if (this.bounds) {
          searchOptions.bounds = this.bounds
        }

        var service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(searchOptions, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            // alert(status)
            return
          }
          const suburbs = []
          predictions.forEach(function(prediction) {
            if (prediction.terms.length === 2) {
              suburbs.push({
                id: prediction.place_id,
                suburb: prediction.terms[0].value,
                state: prediction.terms[0].value,
                country: prediction.terms[1].value
              })
            } else if (prediction.terms.length === 3) {
              suburbs.push({
                id: prediction.place_id,
                suburb: prediction.terms[0].value,
                state: prediction.terms[1].value,
                country: prediction.terms[2].value
              })
            }
          })
          this.suburbs.data = suburbs
        })
      }
      this.suburbs.loading = false
    }, 500),
    pickSuburb: function(event) {
      if (!event) {
        return
      }
      const vm = this
      var service = new window.google.maps.Geocoder()
      service.geocode(
        {
          placeId: event.id
        },
        function(results, status) {
          if (status === 'OK') {
            const place = results[0]
            for (let i = 0; i < place.address_components.length; i++) {
              const addressType = place.address_components[i].types[0]
              switch (addressType) {
                case 'locality':
                  vm.suburb = place.address_components[i]['long_name']
                  break
                case 'postal_code':
                  break
                case 'administrative_area_level_1':
                  break
                case 'country':
                  break
              }
            }
          }
        }
      )
    }
  }
}
</script>
