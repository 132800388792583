<template>
  <div :class="{'tile is-parent is-vertical' : true}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p
        v-if="isTiled"
        class="title">Phones</p>
      <div
        class="field"
        v-for="(phone,index) in activePhones"
        :key="index">
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select v-model="phone.type">
                <option
                  v-for="(value,p) in phoneTypes"
                  :value="value"
                  :key="p">
                  {{ p }}
                </option>
              </select>
            </span>
          </p>
          <p class="control has-icons-left is-expanded">
            <!-- <input v-model="phone.no" class="input" placeholder="Phone" v-fq-validate="v.$each[index].no"> -->
            <the-mask
              v-model="phone.no"
              :mask="phoneNoMask"
              :tokens="phoneNoTokens"
              class="input"
              placeholder="Phone"
              v-fq-validate="v.$each[index].no"
              v-focus-inserted="{ focus: phone.isNew}" />
            <span class="icon is-small is-left">
              <i class="mdi mdi-phone mdi-18px" />
            </span>
          </p>
          <p class="control">
            <a
              class="button is-gold is-outlined"
              @click="setDefault(phone, index)">
              <span class="icon is-medium">
                <i
                  class="mdi mdi-24px"
                  :class="[phone.isDefault ? 'mdi-star': 'mdi-star-outline']" />
              </span>
            </a>
          </p>
          <p class="control">
            <a
              class="button is-danger is-outlined"
              @click="deletePhone(phone, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </a>
          </p>
        </div>
        <div class="field">
          <span
            class="help is-danger"
            v-if="!v.$each[index].no.required">Phone no field is required</span>
        </div>
      </div>
      <div class="field">
        <p class="control is-pulled-right">
          <a
            class="button is-primary tooltip is-tooltip-topright"
            :disabled="v.$invalid"
            @click="addPhone()"
            data-tooltip="Add new phone">
            <span class="icon is-small">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </p>
      </div>
    </article>
  </div>
</template>

<script>
import PhoneTypes from '@/enums/PhoneTypes'
import { TheMask } from 'vue-the-mask'
import { InputValidationDirective } from '@/components/directives'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'PhoneList',
  directives: {
    FocusInserted
  },
  components: {
    TheMask
  },
  mixins: [InputValidationDirective],
  props: {
    phones: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  data() {
    return {
      phoneNoTokens: {
        P: {
          pattern: /[0-9, ()\-+]/
        }
      },
      phoneNoMask: Array(31).join('P') // length of phoneNo field in database
    }
  },
  computed: {
    phoneTypes: function() {
      return PhoneTypes
    },
    activePhones: function() {
      if (typeof this.phones !== 'undefined') {
        return this.phones.filter(x => !x.isDeleted)
      }
      return []
    }
  },
  methods: {
    setDefault: function(phone, index) {
      this.phones.map(function(e, i) {
        e.isDefault = i === index
      })
    },

    addPhone: function() {
      if (this.v.$invalid) {
        return
      }

      const newPhone = {
        id: null,
        type: 0,
        no: '',
        isDefault: this.activePhones.filter(x => x.isDefault).length === 0,
        isNew: true,
        isDeleted: false
      }

      this.phones.push(newPhone)
    },
    deletePhone: function(phone, index) {
      // phone.isNew = false // otherwise it's sent to server and inserted
      if (phone.isNew) {
        this.phones.splice(index, 1)
      } else {
        phone.isDeleted = true
        phone.no = 'deleted' // to avoid validation errors
      }

      if (phone.isDefault && this.activePhones.length > 0) {
        this.activePhones[0].isDefault = true
        this.activePhones[0].isDirty = true
      }
    }
  }
}
</script>
