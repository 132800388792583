import axios from 'axios'

const service = axios.create({
  baseURL: 'https://api.mapbox.com/geocoding/v5'
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
